import ServiceBase from '..'
import { Article } from './models/Article'
import { BannerBlog } from './models/Banner'
import { GetListFilterAdvancedResponse } from './response/GetListFilterAdvancedResponse'

class ArticleService extends ServiceBase {
  sleep(ms: any) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  async getListFilterAdvanced(
    page: number | any,
    pageSize = 5,
    categoryNameList: any = [],
  ): Promise<GetListFilterAdvancedResponse> {
    const config = useRuntimeConfig()

    return await $fetch<GetListFilterAdvancedResponse>(`blog/filter/advanced`, {
      baseURL: config.public.apiBase,
      method: 'POST',
      headers: this.headers,
      body: {
        categoryNames: categoryNameList,
      },
      query: {
        pageSize,
        page,
      },
    })
  }

  async getByTitle(title: string): Promise<GetListFilterAdvancedResponse> {
    const config = useRuntimeConfig()

    return await $fetch(`blog/filter/title`, {
      baseURL: config.public.apiBase,
      method: 'GET',
      headers: this.headers,
      query: {
        FormattedTitle: title,
      },
    })
  }

  async getByProduct(value: number, size = 3): Promise<GetListFilterAdvancedResponse> {
    const config = useRuntimeConfig()

    return await $fetch(`blog/filter/advanced?pageSize=${size}&page=1`, {
      baseURL: config.public.apiBase,
      method: 'POST',
      headers: this.headers,
      body: {
        productId: value,
      },
    })
  }

  async getBanner(): Promise<BannerBlog> {
    const config = useRuntimeConfig()
    return await $fetch<BannerBlog>(`/banner`, {
      baseURL: config.public.apiBase,
      method: 'GET',
      headers: this.headers,
    })
  }

  async find(name: any): Promise<Article[]> {
    const config = useRuntimeConfig()

    return await $fetch(`/blog/filter/name/${name}`, {
      baseURL: config.public.apiBase,
      method: 'GET',
      headers: this.headers,
    })
  }
}

export default new ArticleService()
